import React from 'react';
import DefaultLayout from '../../layouts/DefaultLayout';
import TemplateColumn from '../../layouts/TemplateColumn';
import { PageHeader, PageIntro, PageSection, RelatedContent, SecondaryCallout, TestingDefinition, ReferencesBlock, AnchorLinks, PageSubSection, Cutpoint } from '../../components';
import { jobCodes } from '../../util/data/jobCodes';

// SEO INFORMATION
const pageMeta = {
    title: `MSI/MMR Testing | KEYTRUDA® (pembrolizumab) | HCP`,
    keywords: `msi testing, mmr testing`,
    description: `Health care professionals may find information about Microsatellite Instability (MSI) or DNA Mismatch Repair (MMR) testing as well as indications for KEYTRUDA® (pembrolizumab) that require MSI/MMR testing.`,
    schemaJsonLD: [
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/biomarker-testing/msi-mmr/","@type":"MedicalWebPage","name":"MSI⁠-⁠H/dMMR (Microsatellite Instability-High/Mismatch Repair Deficient)","description":"A deficient MMR (dMMR) system results in the persistence of DNA mismatches in microsatellites that may then be incorporated into the genetic code as mutations. A dMMR system can be hereditary or sporadic in nature. Tumors that have a dMMR system can develop MSI, which is the expansion or reduction in the length of repetitive sequences in tumor DNA compared with normal DNA."}`,
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/biomarker-testing/msi-mmr/","@type":"MedicalAudience","audienceType":"Health Care Professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico."}`
    ]
}

const jobCode = jobCodes[27].jobCode;


const relatedContent = [
    {
        section: 'Biomarker Testing',
        link: {
            url: '/biomarker-testing/biomarker-overview/',
            label: 'Biomarker Overview'
        }
    },
    {
        section: 'Biomarker Testing',
        link: {
            url: '/biomarker-testing/pd-l1/',
            label: 'PD&#8288;-&#8288;L1 Testing & Scoring'
        }
    },
    {
        section: 'Resources',
        link: {
            url: '/resources/hcp-and-patient-resources/',
            label: 'HCP & Patient Resources'
        }
    }
]

const pageReferences = [
    {
        label: "1.",
        text: "Boland CR, Goel A. Microsatellite instability in colorectal cancer. <i>Gastroenterology.</i> 2010;138(6):2073–2087."
    },
    {
        label: "2.",
        text: "Li SKH, Martin A. Mismatch repair and colon cancer: mechanisms and therapies explored. <i>Trends Mol Med.</i> 2016;22(4):274–289."
    },
    {
        label: "3.",
        text: "Dictionary of Cancer Terms. Microsatellite instability. National Cancer Institute. Accessed October 31, 2023. https://www.cancer.gov/publications/dictionaries/cancer-terms/def/microsatellite-instability"
    },
    {
        label: "4.",
        text: "Zhang X, Li J. Era of universal testing of microsatellite instability in colorectal cancer. <i>World J Gastrointest Oncol.</i> 2013;5(2):12–19."
    },
    {
        label: "5.",
        text: "Gan C, Love C, Beshay V, et al. Applicability of next generation sequencing technology in microsatellite instability testing. <i>Genes</i> (Basel). 2015;6(1):46–59."
    },
    {
        label: "6.",
        text: "Salipante SJ, Scroggins SM, Hampel HL, et al. Microsatellite instability detection by next generation sequencing. <i>Clin Chem.</i> 2014;60(9):1192–1199."
    },
    {
        label: "7.",
        text: "Hempelmann JA, Scroggins SM, Pritchard CC, et al. MSIplus for integrated colorectal cancer molecular testing by next-generation sequencing. <i>J Mol Diagn.</i> 2015;17(6):705–714."
    }
]

const anchorLinkModelData = [
    {label: 'Defining MSI/MMR Testing'},
    {label: 'Indications for KEYTRUDA That Require MSI/MMR Testing'}
];

const Page = ({ location }) => {

    return (
        <DefaultLayout pageMeta={pageMeta} location={location} jobCode={jobCode}>
            <div data-template-name="template-c">
                {/* Begin Intro Column */}
                <TemplateColumn id="template-c-intro" maxWidth={824}>
                    <PageHeader title="MSI/MMR Testing" />
                    <PageSection title="Understanding MSI/MMR Testing" bgColor="cloud">
                        <PageIntro> 
                            <p>A deficient MMR (dMMR) system results in the persistence of DNA mismatches in microsatellites that may then be incorporated into the genetic code as mutations. A dMMR system can be hereditary or sporadic in nature. Tumors that have a dMMR system can develop MSI, which is the expansion or reduction in the length of repetitive sequences in tumor DNA compared with normal DNA.<sup>1-3</sup></p>
                            <p>Multiple tests are available to assess MSI/MMR status across advanced solid tumors.<sup>4,5</sup></p>
                            <p>The use of an FDA-approved test is recommended.</p>
                            <p>Information on FDA-approved tests used for patient selection is available at <a href="http://www.fda.gov/CompanionDiagnostics" target="_blank">http://www.fda.gov/CompanionDiagnostics</a></p>
                        </PageIntro>
                        <AnchorLinks items={anchorLinkModelData}/>
                    </PageSection>
                </TemplateColumn>
                {/* End Intro Column */}
                {/* Begin Main Column */}
                <TemplateColumn id="template-c-main">
                    <PageSection title={anchorLinkModelData[0].label}>
                        <SecondaryCallout 
                            title="Biomarker status can be a road map for personalizing treatment in all advanced MSI&#8288;-&#8288;H/dMMR solid tumors" 
                            alt=""
                        />
                        <TestingDefinition title="MSI&#8288;-&#8288;H/dMMR (Microsatellite Instability-High/Mismatch Repair Deficient)" listHeading="MSI&#8288;-&#8288;H/dMMR occurs in different solid tumor types, including:" cancerList={['Colorectal', 'Endometrial', 'Gastric or GEJ', 'Small intestinal', 'Brain','Ovarian', 'Biliary', 'Pancreatic', 'Sarcoma', 'Breast', 'Other<sup>a</sup>', 'Cervical', 'Neuroendocrine', 'Prostate', 'Adrenocortical', 'Mesothelioma', 'Thyroid', 'Small cell lung', 'Bladder', 'Salivary', 'Renal cell' ]} definitions="dMMR = mismatch repair deficient; DNA = deoxyribonucleic acid; GEJ = gastroesophageal junction; HNSCC = head and neck squamous cell carcinoma; MLH = MutL homologue; MMR = mismatch repair; MSI = microsatellite instability; MSI&#8288;-&#8288;H = microsatellite instability-high; NOS = not otherwise specified; PMS = postmeiotic segregation." footnotes={[
                            {
                                label: 'a.',
                                text: 'Includes tumor type: anal, HNSCC, nasopharyngeal, retroperitoneal, testicular, vaginal, vulvar, appendiceal adenocarcinoma, NOS, hepatocellular carcinoma, carcinoma of unknown origin, and abdominal adenocarcinoma.',
                            },
                        ]}>
                            <h5>Types of Testing to Determine Potential Eligibility for KEYTRUDA</h5>
                            <ul>
                                <li>Immunohistochemistry (IHC) detects the presence and absence of MMR protein expression.<sup>4</sup>
                                    <ul>
                                        <li>dMMR is defined as at least 1 protein (MSH 2, MSH 6, PMS 2 and MLH 1) showing loss of expression.<sup>4</sup></li>
                                    </ul>
                                </li>
                                <li>Next-generation sequencing (NGS) compares the length of nucleotide repeats in tumor cells and normal cells.<sup>5</sup>
                                    <ul>
                                        <li>NGS is a gene sequencing technique used to identify genetic mutations or variants.<sup>6,7</sup></li>
                                    </ul>
                                </li>
                            </ul>
                        </TestingDefinition>
                    </PageSection>

                    <PageSection title={anchorLinkModelData[1].label}>
                        <PageSubSection title='Advanced MSI-H/dMMR Cancers'>
                            <Cutpoint indicationId={22} cutpoint="MSI-H or dMMR" subtitleHidden={true}/>
                            <Cutpoint indicationId={23} cutpoint="MSI-H or dMMR" subtitleHidden={true}/>
                            <Cutpoint indicationId={30} cutpoint="MSI-H or dMMR" subtitleHidden={true}/>
                        </PageSubSection>
                    </PageSection>

                    <PageSection bgColor="keytrudaGreen">
                        <RelatedContent items={relatedContent} />
                    </PageSection>

                    <PageSection bgColor="mist">
                        <ReferencesBlock references={pageReferences} />
                    </PageSection>
                </TemplateColumn>
                {/* End Main Column */}
            </div>
        </DefaultLayout>
    );
};

export default Page;
